import BaseModal, { BaseModalProps } from "./index";
import React from "react";
import ModalLoadingMessage from "./components/modal-loading-message";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../stores";
import { clearModal, closeModal } from "../../../stores/modal-slice";
import { cva, cx } from "class-variance-authority";

export type GridSizeType = 6 | 8 | 10 | 12;

export interface GenericBaseModalProps extends BaseModalProps {
  gridSize?: GridSizeType;
  isLoading?: boolean;
  loadingMessage?: string;
  shouldClearModalState?: boolean;
}

const gridSizeClasses = cva("tw-col-span-12", {
  variants: {
    gridSize: {
      6: "md:tw-col-span-8 md:tw-col-start-3 lg:tw-col-span-6 lg:tw-col-start-4",
      8: "md:tw-col-span-8 md:tw-col-start-3",
      10: "md:tw-col-span-10 md:tw-col-start-2",
      12: "",
    },
  },
  defaultVariants: {
    gridSize: 6,
  },
});

const getContentClassName = (
  contentClassName?: string,
  gridSize?: GridSizeType
) => {
  const defaultClasses =
    "generic-modal-content w-100 d-flex flex-column gap-4 p-s16 p-md-s24";
  const gridClasses = gridSizeClasses({ gridSize });

  return contentClassName
    ? cx(contentClassName, gridClasses)
    : cx(defaultClasses, gridClasses);
};

const GenericBaseModal = ({
  gridSize,
  isLoading,
  loadingMessage,
  shouldClearModalState = true,
  ...props
}: GenericBaseModalProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleOnExited = () => {
    if (shouldClearModalState) {
      dispatch(clearModal());
    }
  };

  return (
    <BaseModal
      dialogClassName="tw-container tw-grid tw-grid-cols-12 tw-gap-6"
      titleClassName="flex-grow-1 mb-0 me-s16 typeface-h3 tw-text-blue-900"
      bodyClassName="p-0 typeface-body-regular tw-text-blue-900"
      footerClassName="w-100 d-flex gap-0 justify-content-start p-0 border-0 rounded-0 shadow-none tw-bg-transparent"
      onCloseButtonClick={
        props.onCloseButtonClick
          ? props.onCloseButtonClick
          : () => dispatch(closeModal())
      }
      onEscapeKeyDown={() => dispatch(closeModal())}
      {...props}
      contentClassName={getContentClassName(props.contentClassName, gridSize)}
      headerClassName={cx(
        props.headerClassName ||
          "w-100 p-0 d-flex align-items-start justify-content-between",
        props.title ?? "flex-row-reverse"
      )}
      buttons={
        isLoading ? (
          <ModalLoadingMessage loadingMessage={loadingMessage} />
        ) : (
          props.buttons
        )
      }
      onExited={handleOnExited}
    />
  );
};

export default GenericBaseModal;
