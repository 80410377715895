import { ChangeEvent, FormEvent } from "react";
import { translate as t } from "../../../helpers/i18n";
import TermAndConditionsDashboardComponent from "../terms-and-conditions-dashboard-component";
import TermConditionsDigitalComponent from "../terms-and-conditions-digital-component";
import {
  ACCOUNT_TYPES,
  DIGITAL_TERMS_CONDITIONS_LAST_UPDATED,
  TERMS_CONDITIONS_LAST_UPDATED,
} from "../../../helpers/constants";
import DashboardTermsCheckboxGroup, {
  TermsData,
} from "../terms-and-conditions-dashboard-component/dashboard-terms-checkbox-group";
import DigitalTermsCheckboxGroup from "../terms-and-conditions-digital-component/digital-terms-checkbox-group";
import GenericBaseModal from "../modal/generic-base-modal";
import ModalButtonsWrapper from "../modal/components/modal-buttons-wrapper";
import { Button } from "../../atoms/button";
import { MIXPANEL_EVENTS } from "analytics";
import mixpanel from "mixpanel-browser";
import { useLocation } from "react-router-dom";

interface AcceptTCModalProps {
  module: string;
  show: boolean;
  onClose: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
  isFailure: boolean;
  isUpdatedTermsAndConditions?: boolean;
  termsData: TermsData;
  accountType: `${ACCOUNT_TYPES}`;
  handleChange: (
    e: ChangeEvent<HTMLInputElement>,
    currentValue: boolean
  ) => void;
}

/**
 * @note it may be worthwhile to make the contents of this modal separate,
 * based on the kind of account it is
 */
const AcceptTCModal = ({
  module,
  show,
  onClose,
  onSubmit,
  isLoading,
  isFailure,
  isUpdatedTermsAndConditions,
  handleChange,
  termsData,
  accountType,
}: AcceptTCModalProps) => {
  const { pathname } = useLocation();

  const lastUpdated =
    accountType === ACCOUNT_TYPES.DIGITAL_ACCOUNT
      ? DIGITAL_TERMS_CONDITIONS_LAST_UPDATED
      : TERMS_CONDITIONS_LAST_UPDATED;

  const isDigitalAccount = accountType === ACCOUNT_TYPES.DIGITAL_ACCOUNT;

  const handleCloseModal = (from: string) => {
    if (!isDigitalAccount)
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        module: module,
        button: from,
        path: pathname,
      });

    onClose();
  };

  return (
    <GenericBaseModal
      show={show}
      onClose={() => handleCloseModal("off-module-click-to-close")}
      onCloseButtonClick={() => handleCloseModal("close")}
      fullscreen="md-down"
      title={
        isUpdatedTermsAndConditions
          ? t("updated_terms_conditions.modal_title")
          : t("terms_conditions.modal_title")
      }
      buttons={
        <form onSubmit={onSubmit} noValidate className="w-100 z-2">
          <div className="d-grid gap-3 mb-3">
            {isDigitalAccount ? (
              <DigitalTermsCheckboxGroup />
            ) : (
              <DashboardTermsCheckboxGroup
                termsData={termsData}
                handleChange={handleChange}
              />
            )}

            {isFailure && (
              <div className="d-block invalid-feedback">
                {t("error.regular_message")}
              </div>
            )}
          </div>
          <ModalButtonsWrapper>
            <Button
              data-testid="terms-conditions-agree"
              isLoading={isLoading}
              type="submit"
            >
              {t("terms_conditions.continue_button")}
            </Button>
            <Button
              variant="ghost"
              onClick={() => handleCloseModal("cancel")}
              // explicitly set as non-submit button
              type="button"
            >
              {t("terms_conditions.cancel_button")}
            </Button>
          </ModalButtonsWrapper>
        </form>
      }
    >
      <div className="mb-3">
        <p>{lastUpdated}</p>
      </div>
      <div className="terms-accordion z-1">
        {isDigitalAccount ? (
          <TermConditionsDigitalComponent isContainedInModal />
        ) : (
          <TermAndConditionsDashboardComponent isContainedInModal />
        )}
      </div>
    </GenericBaseModal>
  );
};

export default AcceptTCModal;
