// Include our external dependencies.
import * as React from "react";
import { Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Redirect } from "react-router6-redirect";
import { pages } from "./helpers/pages";
import Loader from "./components/loader";
import PublicLayout from "./modules/layout/public-layout";
import PrivateLayout from "./modules/layout/private-layout";
import { useTrackers } from "./hooks/use-trackers";
import useSessionTimer from "./hooks/use-session-timer";
import useScrollRestore from "./hooks/use-scroll-restore";
import { useMonitoring } from "./hooks/use-monitoring";
import { lazyWithRefresh, shouldShowProfilePage } from "./helpers/utils";
import { CASH_OUT_BASE_URL } from "./helpers/constants";
import useDeepLink from "./hooks/use-deep-link";
import { FEATURE_GATES, useFeatureGate } from "./hooks/use-feature-gate";
import { EXPERIMENTS, useABTest } from "./hooks/use-ab-test";
import { RootState } from "./stores";
import { useSelector } from "react-redux";
import { useIsUserAuthenticated } from "./hooks/use-is-user-authenticated";
import Layout from "./modules/layout";
import { useStatsigClient, useStatsigUser } from "@statsig/react-bindings";
import { getSessionId } from "analytics";

const SSOError = lazyWithRefresh(
  () => import("./modules/sso-error"),
  "sso-error"
);
const HomeValue = lazyWithRefresh(
  () => import("./modules/home-value"),
  "home-value"
);
const NotFound = lazyWithRefresh(
  () => import("./modules/not-found"),
  "not-found"
);
const Profile = lazyWithRefresh(() => import("./modules/profile"), "profile");
const SignUp = lazyWithRefresh(() => import("./modules/sign-up"), "sign-up");
const UnauthenticatedDash = lazyWithRefresh(
  () => import("./modules/unauthenticated-dash"),
  "unauthenticated-dash"
);
const TermsAndConditions = lazyWithRefresh(
  () => import("./modules/terms-and-conditions"),
  "terms-and-conditions"
);
const TermsAndConditionsV2 = lazyWithRefresh(
  () => import("./modules/terms-and-conditions-v2"),
  "terms-and-conditions"
);
const TermsAndConditionsDashboard = lazyWithRefresh(
  () => import("./modules/terms-and-conditions-dashboard"),
  "terms-and-conditions-dashboard"
);
const TermsAndConditionsDashboardV2 = lazyWithRefresh(
  () => import("./modules/terms-and-conditions-dashboard-v2"),
  "terms-and-conditions-dashboard"
);
const TermsAndConditionsDigital = lazyWithRefresh(
  () => import("./modules/terms-and-conditions-digital"),
  "terms-and-conditions-digital"
);
const TermsAndConditionsDigitalV2 = lazyWithRefresh(
  () => import("./modules/terms-and-conditions-digital-v2"),
  "terms-and-conditions-digital"
);
const TermsAndConditionsDigitalHomeEquityLoan = lazyWithRefresh(
  () => import("./modules/terms-and-conditions-digital-home-equity-loan"),
  "terms-and-conditions-digital-home-equity-loan"
);
const TermsAndConditionsDigitalHomeEquityLoanV2 = lazyWithRefresh(
  () => import("./modules/terms-and-conditions-digital-home-equity-loan-v2"),
  "terms-and-conditions-digital-home-equity-loan"
);
const CashOutRefinance = lazyWithRefresh(
  () => import("./modules/products/cash-out-refinance"),
  "cash-out-refinance"
);
const GetAMortgage = lazyWithRefresh(
  () => import("./modules/products/get-a-mortgage"),
  "get-a-mortgage"
);

const HomeEquityLoan = lazyWithRefresh(
  () => import("./modules/products/home-equity-loan"),
  "home-equity-loan"
);

const RateTermRefinance = lazyWithRefresh(
  () => import("./modules/products/rate-term-refinance"),
  "rate-term-refinance"
);
const ErrorPage = lazyWithRefresh(() => import("./modules/error"), "error");
const FrequentlyAskedQuestions = lazyWithRefresh(
  () => import("./modules/faq"),
  "faq"
);

const FrequentlyAskedQuestionsV2 = lazyWithRefresh(
  () => import("./modules/faq/faq-v2"),
  "faq"
);

const UnverifiedEmail = lazyWithRefresh(
  () => import("./components/unverified-email"),
  "unverified-email"
);
const ResourceCenter = lazyWithRefresh(
  () => import("./modules/resource-center"),
  "resource-center"
);
const ResourceCenterArticle = lazyWithRefresh(
  () => import("./modules/resource-center/articles"),
  "articles"
);
const EmptyLayout = lazyWithRefresh(
  () => import("./modules/layout/empty-layout"),
  "empty-layout"
);
const SSORejection = lazyWithRefresh(
  () => import("./modules/sso-rejection"),
  "sso-rejection"
);
const HeaderlessLayout = lazyWithRefresh(
  () => import("./modules/layout/headerless-layout"),
  "headerless-layout"
);
const NpsPage = lazyWithRefresh(() => import("./modules/nps"), "nps");
const AdaAccessibilityPage = lazyWithRefresh(
  () => import("./modules/ada-accessibility"),
  "ada-accessibility"
);
const CashOutRoutes = lazyWithRefresh(
  () => import("./modules/cash-out-loan-quote/helpers/cash-out-routes"),
  "cash-out-routes"
);
const DigitalAccountLayout = lazyWithRefresh(
  () => import("./modules/digital-account-creation/components/main-layout"),
  "digital-account-layout"
);
const DigitalAccountCreation = lazyWithRefresh(
  () => import("./modules/digital-account-creation/pages/index"),
  "digital-account-creation"
);
const HomeRenovationCalculatorPage = lazyWithRefresh(
  () => import("./modules/home-renovation"),
  "home-renovation-calculator"
);

const MortgageTransferMaintenancePage = lazyWithRefresh(
  () => import("./modules/mortgage-transfer-maintenance-page"),
  "mortgage-transfer-maintenance-page"
);

const App: React.FC = () => {
  /**
   * Hooks that require the app (redux or statsig) to be initialized first are called below
   */

  // Initialize DatadogRUM
  useMonitoring();

  // Session timers
  useSessionTimer();

  // Scroll restoration
  useScrollRestore();

  // Initialize trackers
  useTrackers();

  // DeepLinking validation
  useDeepLink();

  const isDigitalAccountCreationEnabled = useFeatureGate(
    FEATURE_GATES.ENABLE_DIGITAL_ACCOUNT_CREATION
  );

  const { isExperimentAvailable: isTavantDigitalHeloanEnabled } = useABTest(
    EXPERIMENTS.TAVANT_DIGITAL_HELOAN
  );

  const isKukunCostCalculatorGate = useFeatureGate(
    FEATURE_GATES.ENABLE_KUKUN_COST_CALCULATOR
  );

  const isLakeviewV2Enabled = useFeatureGate(FEATURE_GATES.ENABLE_LAKEVIEW_2_0);

  const { user } = useSelector((state: RootState) => state.user);
  const isUserAuthenticated = useIsUserAuthenticated();
  const isDigitalAccount =
    !!user?.disclaimer_acceptances?.DIGITAL_ACCOUNT_TERMS_AND_CONDITIONS
      ?.version;

  const termsAndConditionsElement =
    isDigitalAccountCreationEnabled && isUserAuthenticated ? (
      <Navigate
        to={
          isDigitalAccount
            ? pages.termsConditionsDigital
            : pages.termsConditionsDashboard
        }
      />
    ) : isLakeviewV2Enabled ? (
      <TermsAndConditionsV2 />
    ) : (
      <TermsAndConditions />
    );
  const { client } = useStatsigClient();
  const { updateUserAsync } = useStatsigUser();

  /**
   * IMPORTANT - Why this code is here and not in app-initializer:
   * The Statsig user update (updateUserSync) MUST be called within StatsigProvider's context.
   * If we try to update the user outside StatsigProvider (like in app-initializer),
   * it won't work because Statsig's context is not available there.
   */

  // Effect to update Statsig user identity when authentication changes
  useEffect(() => {
    // Get current Statsig user identifier
    const currentStatsigUserId = client.getContext().user.userID;
    // Get session id
    const sessionId = getSessionId();
    // Check if we need to update Statsig user identity:
    // 1. User must be authenticated AND
    // 2. Statsig must still be using the temporary session ID
    const isStatsigUsingTemporaryId = currentStatsigUserId === sessionId;

    const updateStatsig = async () => {
      if (isUserAuthenticated && isStatsigUsingTemporaryId) {
        // Update Statsig with the permanent user identifier
        await updateUserAsync({
          userID: user?.id, // Replace temporary session ID with permanent user ID
          custom: {
            isUserAuthenticated, // Track authentication state
          },
        });
      }
    };
    updateStatsig();
  }, [client, isUserAuthenticated, updateUserAsync, user?.id]);

  return (
    <div
      className="app-component d-flex flex-column fade-in h-auto tw-bg-white"
      data-component="App"
    >
      <Suspense fallback={<Loader />}>
        {/* Core Routing */}
        <Routes>
          {/* Headerless Layout - Account Creation */}
          <Route element={<HeaderlessLayout />}>
            <Route path={pages.signup} element={<SignUp />} />
            <Route path={`${pages.signup}/:step`} element={<SignUp />} />
          </Route>

          {/* Empty layout */}
          <Route element={<EmptyLayout />}>
            <Route path={pages.ssoRejection} element={<SSORejection />} />
            <Route path={pages.ssoError} element={<SSOError />} />
            <Route
              path={pages.mortgageTransferMaintenancePage}
              element={<MortgageTransferMaintenancePage />}
            />
          </Route>

          {/* Protected Routes */}
          <Route element={<PrivateLayout loadingState />}>
            {shouldShowProfilePage(user) && (
              <Route path={pages.profile} element={<Profile />} />
            )}
            <Route path={pages.home} element={<HomeValue />} />
            {isKukunCostCalculatorGate && (
              <Route
                path={pages.homeRenovationCalculator}
                element={<HomeRenovationCalculatorPage />}
              />
            )}
          </Route>

          {/* Unprotected Routes */}
          <Route element={<PublicLayout />}>
            <Route path={pages.index} element={<UnauthenticatedDash />} />
          </Route>

          {/* Digital Account Creation */}
          <Route element={<DigitalAccountLayout />}>
            <Route
              path={pages.digitalAccountCreation}
              element={<DigitalAccountCreation />}
            />
          </Route>

          <Route path={`${CASH_OUT_BASE_URL}/*`} element={<CashOutRoutes />} />

          {/* Global Routes */}
          <Route element={<Layout displayAuthInfo />}>
            <Route
              path={pages.termsConditions}
              element={termsAndConditionsElement}
            />
            {isDigitalAccountCreationEnabled && (
              <>
                <Route
                  path={pages.termsConditionsDashboard}
                  element={
                    isLakeviewV2Enabled ? (
                      <TermsAndConditionsDashboardV2 />
                    ) : (
                      <TermsAndConditionsDashboard />
                    )
                  }
                />
                <Route
                  path={pages.termsConditionsDigital}
                  element={
                    isLakeviewV2Enabled ? (
                      <TermsAndConditionsDigitalV2 />
                    ) : (
                      <TermsAndConditionsDigital />
                    )
                  }
                />
              </>
            )}

            <Route
              path={pages.termsConditionsDigitalHomeEquityLoan}
              element={
                isLakeviewV2Enabled ? (
                  <TermsAndConditionsDigitalHomeEquityLoanV2 />
                ) : (
                  <TermsAndConditionsDigitalHomeEquityLoan />
                )
              }
            />

            <Route
              path={pages.frequentlyAskedQuestions}
              element={
                isLakeviewV2Enabled ? (
                  <FrequentlyAskedQuestionsV2 />
                ) : (
                  <FrequentlyAskedQuestions />
                )
              }
            />

            {/* Auth0 - unverified email */}
            <Route
              path={pages.needToVerifyEmail}
              element={<UnverifiedEmail />}
            />

            {/* PDP Pages */}
            <Route
              path={pages.products.cashOutRefinance}
              element={<CashOutRefinance />}
            />
            <Route
              path={pages.products.getAMortgage}
              element={<GetAMortgage />}
            />
            <Route
              path={pages.products.rateTermRefinance}
              element={<RateTermRefinance />}
            />

            {isTavantDigitalHeloanEnabled && (
              <Route
                path={pages.products.homeEquityLoan}
                element={<HomeEquityLoan />}
              />
            )}
            {/* Resource center */}
            <Route path={pages.resourceCenter} element={<ResourceCenter />} />
            <Route
              path={`${pages.resourceCenter}/:slug`}
              element={<ResourceCenterArticle />}
            />

            {/* Resource center redirects */}
            <Route
              path={pages.resourceCenterOldRoute}
              element={<Navigate to={pages.resourceCenter} replace />}
            />
            <Route
              path={`${pages.resourceCenterOldRoute}/:slug`}
              element={<Redirect to={`${pages.resourceCenter}/:slug`} />}
            />

            {/* NPS Landing Page */}
            <Route path={pages.submitNps} element={<NpsPage />} />

            {/* ADA Compliance Landing Page */}
            <Route
              path={pages.adaAccessibility}
              element={<AdaAccessibilityPage />}
            />

            {/* Generic error page */}
            <Route path={pages.error} element={<ErrorPage />} />

            {/* Default to 404 page */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
